import UserService from "../../../services/user.service";
import OrganizationService from "../../../services/org.service";

export const OrgAction = {
    CreateOrganizationSuccess: "CREATE_ORGANIZATION_SUCCESS",
    CreateOrganizationFailed: "CREATE_ORGANIZATION_FAILED",
    GetOrganizationDetails: "GET_ORGANIZATION_DETAILS",
    GetOrganizationPreference: "GET_ORGANIZATION_PREFERENCE",
    GetOrganizationsForUser: "GET_ORGANIZATIONS",
    SetCurrentOrganization: "SET_CURRENT_ORGANIZATION",
    GetTeamMembers: "GET_TEAM_MEMBERS",
    updateCurrentOrganization: "UPDATE_CURRENT_ORGANIZATION",
    Logout: "LOGOUT",
};

export const createOrganization = (formData, dispatch) => {
    return new Promise((resolve, reject) => {
        UserService.createOrganization(formData)
            .then(res => {
                dispatch({
                    type: OrgAction.CreateOrganizationSuccess,
                    payload: {
                        isOrgRegistered: true,
                        organization: res.data.detail,
                    },
                });
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: OrgAction.CreateOrganizationFailed,
                    payload: { isOrgRegistered: false, organization: null },
                });
                reject(err);
            });
    });
};

export const updateOrganizationDetails = (data, orgId, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.updateOrg(data, orgId)
            .then(res => {
                dispatch({
                    type: OrgAction.updateCurrentOrganization,
                    payload: { organization: res.data.data },
                });
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

/* export const getOrganizationDetails = (dispatch) => {
  return new Promise((resolve, reject) => {
    UserService.getOrganizationDetails()
      .then((res) => {
        dispatch({
          type: OrgAction.GetOrganizationDetails,
          payload: { isOrgRegistered: true, organization: res.data },
        });
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: OrgAction.GetOrganizationDetails,
          payload: { isOrgRegistered: false, organization: null },
        });
        reject(err);
      });
  });
}; */

export const getOrganizationsForUser = dispatch => {
    return new Promise((resolve, reject) => {
        UserService.getOrganizationsForUser()
            .then(res => {
                dispatch({
                    type: OrgAction.GetOrganizationsForUser,
                    payload: { organizations: res.data },
                });
                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: OrgAction.GetOrganizationsForUser,
                    payload: { organizations: [] },
                });
                reject(err);
            });
    });
};

export const setCurrentOrganization = (org, member, dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: OrgAction.SetCurrentOrganization,
            payload: { organization: org, member },
        });
        resolve();
    });
};

export const getTeamMembers = (organization, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.getTeam(organization._id, null, null, null)
            .then(res => {
                const membersData = res.data.team;
                dispatch({
                    type: OrgAction.SetCurrentOrganization,
                    payload: { organization, member: membersData },
                });
                // dispatch({
                //     type: OrgAction.GetTeamMembers,
                //     payload: { organization: organization, member: membersData },
                // });
                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: OrgAction.GetTeamMembers,
                    payload: { organizations: [] },
                });
                reject(err);
            });
    });
};

export const getAllTeamMembers = (organization, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.getAllTeams(organization._id, null, null, null)
            .then(res => {
                const membersData = res.data.team;
                dispatch({
                    type: OrgAction.SetCurrentOrganization,
                    payload: { organization, member: membersData },
                });
                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: OrgAction.GetTeamMembers,
                    payload: { organizations: [] },
                });
                reject(err);
            });
    });
};

export const getAllUserDetails = (orgId, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.getAllUserDetails(orgId, dispatch)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const deleteInvite = (orgId, inviteId, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.deleteInvite(orgId, inviteId)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const deleteInviteForUser = (inviteId, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.deleteInviteForUser(inviteId)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const deleteTeamMember = (orgId, memberId, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.deleteTeamMember(orgId, memberId)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const updateTeamMember = (orgId, memberId, teamData, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.updateTeamMember(orgId, memberId, teamData)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const createTeamMember = (orgId, teamData, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.createTeamMember(orgId, teamData)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const addUserInOrgAndUserGroup = (orgId, teamData, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.addUserInOrgAndUserGroup(orgId, teamData)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const acceptInvite = (inviteId, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.acceptInvite(inviteId)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const getInvitationList = (status = "pending", dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.getInvitationList(status)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const editUserGroupMapping = (orgId, teamData, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.editUserGroupMapping(orgId, teamData)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const searchUsers = (orgId, email, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.searchUsers(orgId, email)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const getOrganizationDetailsById = (currOrgId, orgIds, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.getOrganizationDetailsById(currOrgId, orgIds)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};

export const getUsersByIds = (ids, dispatch) => {
    return new Promise((resolve, reject) => {
        UserService.getUsersByIds(ids)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};
