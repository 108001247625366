import UserService from "services/user.service";
import OrgService from "services/org.service";

import { OrgAction } from "../common/redux/actions/orgActions";
import store from "../common/redux/store";
const OrganizationGuard = async () => {
    const storeState = store.getState();
    const path = window.location.pathname;
    // const isSetupOrgPath = path.includes("setup-org");
    const isChooseOrgPath = path.includes("org/choose-org");
    // const isOnboardingPath = path.includes("onboarding");
    const isHome = path.includes("organization");
    const pathArray = path.split("/");
    if (storeState["organizationDetails"]["organization"]) {
        // if we have list of organizations in store
        if (isHome) {
            const orgId = pathArray[pathArray.indexOf("organization") + 1];
            if (
                orgId !== storeState["organizationDetails"]["organization"]._id
            ) {
                return "/org/choose-org";
            }
            const userId =
                storeState["authenticationDetails"]?.["user"]?.["_id"];
            if (!userId) return "/";
            const teamResponse = await OrgService.getAllTeams(orgId);
            store.dispatch({
                type: OrgAction.GetOrganizationDetails,
                payload: {
                    member: teamResponse.data.team,
                },
            });
        }
        // if (isSetupOrgPath) {
        //   return `/organization/${storeState["organizationDetails"]["organization"]["_id"]}/home`;
        // } else {
        return "";
        // }
        // });
    } else if (storeState["organizationDetails"]["organizations"]?.length) {
        // if we have current org in redux store then do not make the API call
        if (isHome) {
            const orgId = pathArray[pathArray.indexOf("organization") + 1];
            if (
                !storeState["organizationDetails"]["organizations"].filter(
                    org => org._id === orgId,
                ).length
            ) {
                return "/org/choose-org";
            }
        }
        return "";
    } else {
        if (isChooseOrgPath) {
            try {
                const res = await UserService.getOrganizationsForUser();
                store.dispatch({
                    type: OrgAction.GetOrganizationsForUser,
                    payload: { organizations: res.data },
                });
            } catch (err) {
                console.error(err);
                // if (!userOnboardingRequired(storeState)) {
                //     return "/setup-org";
                // }
                return "";
            }
            return "";
        }
        // if (isSetupOrgPath) {
        //     try {
        //         const res = await UserService.getOrganizationsForUser();
        //         store.dispatch({
        //             type: OrgAction.GetOrganizationsForUser,
        //             payload: { organizations: res.data },
        //         });
        //     } catch (err) {
        //         return "";
        //     }
        //     return "";
        // }
        // if (isOnboardingPath) {
        //     if (!userOnboardingRequired(storeState)) {
        //         return "/setup-org";
        //     }
        //     return "";
        // }
        try {
            const orgId = pathArray[pathArray.indexOf("organization") + 1];
            if (!orgId) {
                // if (isSetupOrgPath) {
                //     return "";
                // }
                // on reload if get org by orgId fails, should be
                return "";
            }
            const res = await UserService.getOrganizationDetailsById(orgId);
            const teamResponse = await OrgService.getAllTeams(orgId);
            store.dispatch({
                type: OrgAction.GetOrganizationDetails,
                payload: {
                    isOrgRegistered: true,
                    organization: res.data.detail,
                    member: teamResponse.data.team,
                },
            });
            return "";
        } catch (err) {
            console.error(err);
            // if (isSetupOrgPath) {
            //     return "";
            // }
            // on reload if get org by orgId fails, should be
            return "org/choose-org";
        }
    }
};

// const userOnboardingRequired = (storeState) => {
//     const onboardingMetaExists =
//         storeState?.["authenticationDetails"]?.["user"]?.["meta"]?.["phoneNumber"];
//     const isSameUser =
//         localStorage.getItem("OptionalUserMetaId") ===
//         storeState?.["authenticationDetails"]?.["user"]?.["_id"];
//     return !(isSameUser || onboardingMetaExists);
// };

export default OrganizationGuard;
