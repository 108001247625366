export const MenuAction = {
    SetShowTopNavBar: "SET_SHOW_TOP_NAVBAR",
    SetShowTabBar: "SET_SHOW_TAB_BAR",
};

export const setShowTabBar = (showTabBar, dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: MenuAction.SetShowTabBar,
            payload: { showTabBar },
        });
        resolve();
    });
};

export const setShowNavbar = (showTopNavbar, dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: MenuAction.SetShowTopNavBar,
            payload: { showTopNavbar },
        });
        resolve();
    });
};
