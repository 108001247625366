import { lazy } from "react";

import executor from "./common/utils/executor";
import AuthGuard from "./guards/auth.guard";
import OrganizationGuard from "./guards/organization.guard";
import NotFound from "./pages/NotFound";

const Auth = lazy(() => import("./pages/Auth"));
const Organization = lazy(() => import("./pages/Organization"));
const Home = lazy(() => import("./pages/Home"));
const Platform = lazy(() => import("./pages/Platform"));

const routes = [
    {
        path: "/auth",
        exact: false,
        component: Auth,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/org",
        exact: false,
        component: Organization,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/organization/:orgId",
        exact: false,
        component: Platform,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/privacy-policy",
        exact: true,
        component: NotFound,
        guard: () => {
            return "";
        },
    },
    {
        path: "/",
        exact: false,
        component: Home,
        guard: () => {
            return "";
        },
    },
];

export default routes;
