import React from "react";
import styled from "styled-components";

const LoaderContainer = styled.div`
    position: absolute;
    top: -25%;
    left: -25%;
    z-index: 10010;
    box-sizing: border-box;
    width: 150%;
    height: 150%;
    backdrop-filter: brightness(0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 24px;
    }

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.005em;
        color: #fff;
    }
    span {
        transform: scale(0.75);
    }
`;
const Loader = ({ show = false }) => {
    return (
        <>
            {show && (
                <LoaderContainer>
                    <div>
                        <span className="loader"></span>
                        <p>Loading...</p>
                    </div>
                </LoaderContainer>
            )}
        </>
    );
};

export default Loader;
