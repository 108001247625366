import { Button } from "@gofynd/nitrozen-react";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

import NotFoundIcon from "../../common/assets/NotFound.svg";
import Logo from "../../common/assets/Logo";

const Container = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    background: #fff;
    img {
        width: 32.4rem;
        height: 20.4691rem;
    }
    p {
        color: var(--light-primary-grey-80, rgba(0, 0, 0, 0.65));
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.4rem;
        letter-spacing: -0.008rem;
    }
`;

const LogoSection = styled.a`
    all: unset;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    gap: 0.8rem;
    padding: 1.8rem 4.8rem;
    align-items: center;
    cursor: pointer;
    img {
        width: 4rem;
        height: 4rem;
    }
    p {
        color: var(--light-primary-grey-100, #141414);
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.4rem;
        letter-spacing: -0.008rem;
    }
`;
export default function NotFound({ showLogo = false }) {
    const history = useHistory();
    const isUserLoggedIn = useSelector(
        state => state?.authenticationDetails?.isUserLoggedIn,
    );

    return (
        <Container>
            {(!isUserLoggedIn || showLogo) && (
                <LogoSection href="/">
                    <Logo black />
                </LogoSection>
            )}
            <img src={NotFoundIcon} alt="Not Found" />
            <p>There was an error the requested page was not found</p>
            <Button theme="secondary" onClick={() => history.push("/")}>
                Return to Homepage
            </Button>
        </Container>
    );
}
