const environment = window._conf || {
    VALUE: "development",
    PORT: 9090,
    newrelic_app: "",
    newrelic_license_key: "",
    API_DOMAIN: "api.sit.xcatalogz0.de",
    CATALOG_CLOUD_BASE_URL: "https://local.sit.xcatalogz0.de:9090",
    BALROG_MAIN_URL: "https://api.sit.xcatalogz0.de/service/panel/users",
    NENYA_MAIN_URL: "https://api.sit.xcatalogz0.de/service/panel/templates",
    RINGIL_MAIN_URL: "https://api.sit.xcatalogz0.de/service/panel/integrations",
    MITHRANDIR_MAIN_URL:
        "https://api.sit.xcatalogz0.de/service/panel/organization",
    VALAR_MAIN_URL: "https://api.sit.xcatalogz0.de/service/panel/catalog",
    VILYA_PLTM_URL: "https://api.sit.xcatalogz0.de/service/panel/assets",
    PALANTIR_PLTM_URL: "https://api.sit.xcatalogz0.de/service/panel/webhook",
    // PALANTIR_MAIN_URL: "http://loclahost:5000",
};

export default environment;
