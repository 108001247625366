import React from "react";

const Logo = ({ black = false }) => {
    return (
        <svg
            width="164"
            height="22"
            viewBox="0 0 164 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_10507_621920)">
                <path
                    d="M21.9906 13.1164L13.5776 21.553C12.983 22.1492 12.0163 22.1492 11.4177 21.553L1.94495 12.0537C1.35038 11.4575 1.35038 10.4881 1.94495 9.88779L9.50453 2.31515C9.9899 1.87304 10.6007 1.88521 11.0617 2.15291L11.5633 2.65586C11.7655 3.00062 11.81 3.43867 11.5916 3.89295L9.35488 8.51279C8.70772 9.85128 10.2649 11.3926 11.3125 10.3421L15.2642 6.37119L21.9906 13.1164Z"
                    fill="url(#paint0_linear_10507_621920)"
                />
                <path
                    d="M9.20898 10.0702C9.20898 10.0702 9.46785 10.3217 10.0503 10.3217C10.5195 10.3217 10.7743 10.0459 10.7743 10.0459L20.425 0.445226C21.0196 -0.151013 21.9862 -0.151013 22.5849 0.445226L32.0576 9.94449C32.6522 10.5407 32.6522 11.5101 32.0576 12.1104L22.3867 21.7273C21.8892 22.1086 21.2825 22.0477 20.8497 21.7435L20.514 21.4069C20.2672 21.054 10.0624 10.926 10.0624 10.926L9.20898 10.0702Z"
                    fill="#FC6770"
                />
                <path
                    d="M14.8556 5.96143L10.9039 9.9323C9.85631 10.9869 8.14135 9.76601 8.7885 8.42345L11.1789 3.48319C11.3973 3.02891 11.3488 2.59086 11.1506 2.24609L14.8556 5.96143Z"
                    fill="url(#paint1_linear_10507_621920)"
                />
                <path
                    d="M46.4246 17.7624C45.5186 17.7624 44.6854 17.6083 43.9331 17.296C43.1807 16.9836 42.5295 16.5375 41.9795 15.9575C41.4294 15.3775 41.0047 14.6879 40.6973 13.8929C40.3939 13.098 40.2402 12.2259 40.2402 11.2768C40.2402 10.3277 40.402 9.52052 40.7256 8.74176C41.0492 7.963 41.4941 7.28158 42.0684 6.69346C42.6428 6.10533 43.3102 5.65105 44.0746 5.32657C44.8391 5.00209 45.6682 4.83984 46.5662 4.83984C47.1769 4.83984 47.7675 4.92908 48.3418 5.10754C48.9162 5.28601 49.442 5.53748 49.9192 5.85386C50.3965 6.17023 50.7969 6.53933 51.1205 6.9571L49.6321 8.59169C49.3206 8.2672 49.0011 7.99545 48.6735 7.77237C48.3458 7.54928 48.0061 7.38299 47.6502 7.26942C47.2983 7.15585 46.9342 7.09906 46.5662 7.09906C46.0161 7.09906 45.4984 7.20046 45.017 7.40327C44.5317 7.60607 44.1151 7.89405 43.7632 8.2672C43.4113 8.64036 43.1322 9.07841 42.93 9.58947C42.7277 10.0965 42.6266 10.6643 42.6266 11.289C42.6266 11.9136 42.7237 12.4977 42.9219 13.0128C43.1201 13.5279 43.3992 13.97 43.7632 14.3432C44.1272 14.7163 44.564 15.0002 45.0696 15.1949C45.5793 15.3937 46.1374 15.491 46.7441 15.491C47.1365 15.491 47.5207 15.4383 47.8888 15.3288C48.2609 15.2193 48.6007 15.0692 48.9202 14.8704C49.2357 14.6717 49.5269 14.4405 49.7898 14.1769L50.9345 16.0264C50.6473 16.3509 50.2671 16.6429 49.7898 16.9066C49.3125 17.1702 48.7786 17.3771 48.1881 17.5272C47.5976 17.6772 47.007 17.7502 46.4246 17.7502V17.7624Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M56.532 17.7621C55.7554 17.7621 55.0516 17.5472 54.4166 17.1172C53.7816 16.6873 53.276 16.0992 52.8917 15.3569C52.5075 14.6147 52.3174 13.771 52.3174 12.8219C52.3174 11.8728 52.5075 11.0332 52.8917 10.2869C53.2719 9.54459 53.7937 8.96458 54.449 8.54275C55.1042 8.12092 55.8484 7.91406 56.6695 7.91406C57.1468 7.91406 57.5836 7.98302 57.9759 8.12092C58.3683 8.25883 58.7161 8.45352 59.0154 8.70499C59.3147 8.95647 59.5615 9.24445 59.7597 9.56893C59.9579 9.89341 60.0913 10.2382 60.1641 10.6113L59.6788 10.4856V8.11281H61.8993V17.5837H59.6585V15.3204L60.1803 15.2312C60.0954 15.5557 59.9457 15.868 59.7233 16.1762C59.5008 16.4845 59.2258 16.7522 58.8981 16.9834C58.5705 17.2186 58.2065 17.4052 57.8061 17.5512C57.4056 17.6972 56.9809 17.7662 56.5239 17.7662L56.532 17.7621ZM57.1427 15.8031C57.6564 15.8031 58.1094 15.6773 58.5058 15.4259C58.9022 15.1744 59.2055 14.8256 59.428 14.3753C59.6505 13.9251 59.7597 13.41 59.7597 12.8219C59.7597 12.2338 59.6505 11.7389 59.428 11.2927C59.2055 10.8506 58.8981 10.5018 58.5058 10.2503C58.1135 9.99887 57.6564 9.87313 57.1427 9.87313C56.6291 9.87313 56.1801 9.99887 55.7918 10.2503C55.4035 10.5018 55.1002 10.8506 54.8858 11.2927C54.6714 11.7349 54.5622 12.2459 54.5622 12.8219C54.5622 13.3978 54.6714 13.9251 54.8858 14.3753C55.1002 14.8256 55.4035 15.1744 55.7918 15.4259C56.1801 15.6773 56.6291 15.8031 57.1427 15.8031Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M63.627 10.1607V8.11242H69.7547V10.1607H63.627ZM65.4916 17.5833V5.70312H67.6959V17.5792H65.4916V17.5833Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M74.9158 17.7621C74.1392 17.7621 73.4354 17.5472 72.8004 17.1172C72.1654 16.6873 71.6598 16.0992 71.2755 15.3569C70.8913 14.6147 70.7012 13.771 70.7012 12.8219C70.7012 11.8728 70.8913 11.0332 71.2755 10.2869C71.6557 9.54459 72.1775 8.96458 72.8327 8.54275C73.488 8.12092 74.2322 7.91406 75.0533 7.91406C75.5306 7.91406 75.9674 7.98302 76.3597 8.12092C76.7521 8.25883 77.0999 8.45352 77.3992 8.70499C77.6985 8.95647 77.9453 9.24445 78.1435 9.56893C78.3416 9.89341 78.4751 10.2382 78.5479 10.6113L78.0626 10.4856V8.11281H80.2831V17.5837H78.0423V15.3204L78.5641 15.2312C78.4792 15.5557 78.3295 15.868 78.107 16.1762C77.8846 16.4845 77.6096 16.7522 77.2819 16.9834C76.9543 17.2186 76.5903 17.4052 76.1899 17.5512C75.7894 17.6972 75.3647 17.7662 74.9077 17.7662L74.9158 17.7621ZM75.5265 15.8031C76.0402 15.8031 76.4932 15.6773 76.8896 15.4259C77.286 15.1744 77.5893 14.8256 77.8118 14.3753C78.0342 13.9251 78.1435 13.41 78.1435 12.8219C78.1435 12.2338 78.0342 11.7389 77.8118 11.2927C77.5893 10.8506 77.2819 10.5018 76.8896 10.2503C76.4973 9.99887 76.0402 9.87313 75.5265 9.87313C75.0128 9.87313 74.5639 9.99887 74.1756 10.2503C73.7873 10.5018 73.4839 10.8506 73.2696 11.2927C73.0552 11.7349 72.946 12.2459 72.946 12.8219C72.946 13.3978 73.0552 13.9251 73.2696 14.3753C73.4839 14.8256 73.7873 15.1744 74.1756 15.4259C74.5639 15.6773 75.0128 15.8031 75.5265 15.8031Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M82.8711 17.583V4.2832H85.0916V17.5789H82.8711V17.583Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M91.8308 17.7621C90.8883 17.7621 90.043 17.5512 89.3028 17.1253C88.5626 16.6995 87.9762 16.1154 87.5474 15.3731C87.1187 14.6309 86.9043 13.7872 86.9043 12.8381C86.9043 11.889 87.1187 11.0453 87.5474 10.3031C87.9762 9.56082 88.5626 8.97675 89.3028 8.55086C90.043 8.12498 90.8843 7.91406 91.8308 7.91406C92.7772 7.91406 93.5983 8.12498 94.3385 8.55086C95.0787 8.97675 95.6611 9.56082 96.0858 10.3031C96.5105 11.0453 96.7208 11.889 96.7208 12.8381C96.7208 13.7872 96.5105 14.6309 96.0858 15.3731C95.6611 16.1154 95.0787 16.6995 94.3385 17.1253C93.5983 17.5512 92.7611 17.7621 91.8308 17.7621ZM91.8308 15.8031C92.3444 15.8031 92.8015 15.6692 93.2019 15.4096C93.6024 15.1501 93.9178 14.7931 94.1524 14.351C94.387 13.9089 94.4962 13.406 94.4841 12.8422C94.4962 12.2662 94.387 11.7592 94.1524 11.313C93.9178 10.8709 93.6024 10.518 93.2019 10.2625C92.8015 10.007 92.3444 9.87719 91.8308 9.87719C91.3171 9.87719 90.856 10.007 90.4434 10.2625C90.0309 10.5221 89.7113 10.875 89.4848 11.3211C89.2583 11.7714 89.1451 12.2784 89.1451 12.8381C89.1451 13.3978 89.2583 13.9048 89.4848 14.347C89.7113 14.7891 90.0309 15.1419 90.4434 15.4056C90.856 15.6692 91.3171 15.799 91.8308 15.799V15.8031Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M102.938 21.7165C102.234 21.7165 101.53 21.607 100.83 21.392C100.131 21.177 99.5684 20.8931 99.1356 20.5483L99.9243 18.9138C100.175 19.0922 100.454 19.2504 100.766 19.3802C101.077 19.5141 101.409 19.6155 101.761 19.6844C102.113 19.7574 102.464 19.7939 102.808 19.7939C103.443 19.7939 103.969 19.6966 104.394 19.4978C104.818 19.2991 105.138 18.9989 105.352 18.6014C105.567 18.1999 105.676 17.701 105.676 17.1007V15.3566L105.999 15.4823C105.927 15.8555 105.729 16.2084 105.409 16.541C105.085 16.8776 104.681 17.1494 104.192 17.3603C103.702 17.5712 103.201 17.6767 102.687 17.6767C101.793 17.6767 101 17.4658 100.313 17.048C99.625 16.6302 99.083 16.0583 98.6826 15.3323C98.2822 14.6062 98.084 13.7707 98.084 12.8256C98.084 11.8806 98.2822 11.0369 98.6745 10.3028C99.0669 9.56863 99.6088 8.98456 100.296 8.55867C100.984 8.13279 101.757 7.92188 102.618 7.92188C102.978 7.92188 103.322 7.96244 103.658 8.04761C103.993 8.13279 104.301 8.25041 104.58 8.4086C104.859 8.56679 105.114 8.73714 105.34 8.92777C105.567 9.11841 105.753 9.32121 105.894 9.54024C106.036 9.75521 106.129 9.96612 106.161 10.1689L105.696 10.3109L105.733 8.12062H107.901V16.9425C107.901 17.7091 107.787 18.3865 107.561 18.9746C107.334 19.5627 107.007 20.0616 106.574 20.4753C106.145 20.8891 105.623 21.2014 105.017 21.4082C104.406 21.6191 103.714 21.7246 102.938 21.7246V21.7165ZM103.027 15.7866C103.565 15.7866 104.038 15.6608 104.45 15.4093C104.863 15.1579 105.182 14.809 105.409 14.3669C105.635 13.9248 105.749 13.4138 105.749 12.8378C105.749 12.2618 105.631 11.7346 105.401 11.2843C105.17 10.8341 104.847 10.4853 104.442 10.2338C104.038 9.98235 103.565 9.85661 103.027 9.85661C102.489 9.85661 102.015 9.9864 101.611 10.2419C101.207 10.4975 100.887 10.8544 100.652 11.3006C100.418 11.7508 100.305 12.2618 100.305 12.8378C100.305 13.4138 100.422 13.9086 100.652 14.3548C100.887 14.805 101.207 15.1538 101.611 15.4053C102.015 15.6568 102.489 15.7825 103.027 15.7825V15.7866Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M111.29 17.782C110.861 17.782 110.53 17.6562 110.295 17.4048C110.061 17.1533 109.947 16.8004 109.947 16.3461C109.947 15.9284 110.069 15.5836 110.315 15.3119C110.562 15.0442 110.886 14.9062 111.29 14.9062C111.719 14.9062 112.051 15.0279 112.285 15.2754C112.52 15.5228 112.633 15.8756 112.633 16.3461C112.633 16.7639 112.512 17.1087 112.265 17.3804C112.018 17.6522 111.695 17.786 111.286 17.786L111.29 17.782Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M120.067 17.7624C119.161 17.7624 118.328 17.6083 117.576 17.296C116.823 16.9836 116.172 16.5375 115.622 15.9575C115.072 15.3775 114.647 14.6879 114.34 13.8929C114.037 13.098 113.883 12.2259 113.883 11.2768C113.883 10.3277 114.045 9.52052 114.368 8.74176C114.692 7.963 115.137 7.28158 115.711 6.69346C116.285 6.10533 116.953 5.65105 117.717 5.32657C118.482 5.00209 119.311 4.83984 120.209 4.83984C120.819 4.83984 121.41 4.92908 121.984 5.10754C122.559 5.28601 123.085 5.53748 123.562 5.85386C124.039 6.17023 124.44 6.53933 124.763 6.9571L123.275 8.59169C122.963 8.2672 122.644 7.99545 122.316 7.77237C121.988 7.54928 121.649 7.38299 121.293 7.26942C120.941 7.15585 120.577 7.09906 120.209 7.09906C119.659 7.09906 119.141 7.20046 118.66 7.40327C118.174 7.60607 117.758 7.89405 117.406 8.2672C117.054 8.64036 116.775 9.07841 116.573 9.58947C116.37 10.0965 116.269 10.6643 116.269 11.289C116.269 11.9136 116.366 12.4977 116.564 13.0128C116.763 13.5279 117.042 13.97 117.406 14.3432C117.77 14.7163 118.207 15.0002 118.712 15.1949C119.222 15.3937 119.78 15.491 120.387 15.491C120.779 15.491 121.163 15.4383 121.531 15.3288C121.903 15.2193 122.243 15.0692 122.563 14.8704C122.878 14.6717 123.169 14.4405 123.432 14.1769L124.577 16.0264C124.29 16.3509 123.91 16.6429 123.432 16.9066C122.955 17.1702 122.421 17.3771 121.831 17.5272C121.24 17.6772 120.65 17.7502 120.067 17.7502V17.7624Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M126.627 17.583V4.2832H128.848V17.5789H126.627V17.583Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M135.587 17.7621C134.644 17.7621 133.799 17.5512 133.059 17.1253C132.319 16.6995 131.732 16.1154 131.303 15.3731C130.875 14.6309 130.66 13.7872 130.66 12.8381C130.66 11.889 130.875 11.0453 131.303 10.3031C131.732 9.56082 132.319 8.97675 133.059 8.55086C133.799 8.12498 134.64 7.91406 135.587 7.91406C136.533 7.91406 137.354 8.12498 138.094 8.55086C138.835 8.97675 139.417 9.56082 139.842 10.3031C140.266 11.0453 140.477 11.889 140.477 12.8381C140.477 13.7872 140.266 14.6309 139.842 15.3731C139.417 16.1154 138.835 16.6995 138.094 17.1253C137.354 17.5512 136.517 17.7621 135.587 17.7621ZM135.587 15.8031C136.1 15.8031 136.557 15.6692 136.958 15.4096C137.358 15.1501 137.674 14.7931 137.908 14.351C138.143 13.9089 138.252 13.406 138.24 12.8422C138.252 12.2662 138.143 11.7592 137.908 11.313C137.674 10.8709 137.358 10.518 136.958 10.2625C136.557 10.007 136.1 9.87719 135.587 9.87719C135.073 9.87719 134.612 10.007 134.199 10.2625C133.787 10.5221 133.467 10.875 133.241 11.3211C133.014 11.7714 132.901 12.2784 132.901 12.8381C132.901 13.3978 133.014 13.9048 133.241 14.347C133.467 14.7891 133.787 15.1419 134.199 15.4056C134.612 15.6692 135.073 15.799 135.587 15.799V15.8031Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M145.727 17.7607C145.047 17.7607 144.453 17.6065 143.951 17.3023C143.45 16.9981 143.065 16.5601 142.806 15.9922C142.543 15.4244 142.414 14.743 142.414 13.952V8.11133H144.618V13.4856C144.618 13.9885 144.695 14.4185 144.853 14.7714C145.007 15.1242 145.237 15.396 145.533 15.5907C145.832 15.7813 146.188 15.8787 146.608 15.8787C146.92 15.8787 147.203 15.83 147.458 15.7245C147.713 15.6231 147.939 15.4771 148.129 15.2946C148.319 15.108 148.469 14.889 148.578 14.6294C148.687 14.3698 148.74 14.094 148.74 13.7939V8.11538H150.944V17.5863H148.793L148.756 15.611L149.149 15.396C149.007 15.8503 148.764 16.2559 148.425 16.6087C148.085 16.9616 147.68 17.2455 147.215 17.4524C146.75 17.6633 146.252 17.7688 145.727 17.7688V17.7607Z"
                    fill={black ? "black" : "white"}
                />
                <path
                    d="M157.323 17.7614C156.486 17.7614 155.737 17.5505 155.074 17.1246C154.411 16.6988 153.885 16.1187 153.497 15.3805C153.108 14.6423 152.914 13.7946 152.914 12.8374C152.914 11.8802 153.104 11.0446 153.488 10.3024C153.869 9.56012 154.394 8.97605 155.058 8.55016C155.721 8.12428 156.469 7.91337 157.307 7.91337C157.76 7.91337 158.201 7.98637 158.625 8.12834C159.05 8.2703 159.43 8.46499 159.762 8.71241C160.097 8.95982 160.36 9.23158 160.559 9.53173C160.757 9.83188 160.866 10.1442 160.89 10.4646L160.3 10.5376V4.2832H162.504V17.5789H160.373L160.32 15.3522L160.749 15.3887C160.737 15.6888 160.631 15.9768 160.433 16.2526C160.235 16.5284 159.98 16.7799 159.673 17.007C159.361 17.2342 159.001 17.4167 158.589 17.5546C158.176 17.6925 157.756 17.7614 157.327 17.7614H157.323ZM157.752 15.8916C158.265 15.8916 158.718 15.7578 159.115 15.4982C159.511 15.2386 159.814 14.8735 160.037 14.4193C160.259 13.965 160.368 13.4377 160.368 12.8374C160.368 12.2371 160.259 11.726 160.037 11.2637C159.814 10.8013 159.507 10.4443 159.115 10.1848C158.722 9.92516 158.265 9.79943 157.752 9.79943C157.238 9.79943 156.785 9.92922 156.388 10.1848C155.992 10.4403 155.685 10.8013 155.466 11.2637C155.244 11.726 155.135 12.2493 155.135 12.8374C155.135 13.4255 155.244 13.965 155.466 14.4193C155.689 14.8735 155.996 15.2345 156.388 15.4982C156.781 15.7618 157.238 15.8916 157.752 15.8916Z"
                    fill={black ? "black" : "white"}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_10507_621920"
                    x1="15.0782"
                    y1="8.66286"
                    x2="0.459509"
                    y2="22.0922"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E30513" />
                    <stop offset="1" stopColor="#FC6770" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_10507_621920"
                    x1="7.7571"
                    y1="8.29366"
                    x2="25.4832"
                    y2="-3.50421"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#660209" />
                    <stop offset="1" stopColor="#E30513" />
                </linearGradient>
                <clipPath id="clip0_10507_621920">
                    <rect
                        width="161"
                        height="22"
                        fill={black ? "black" : "white"}
                        transform="translate(1.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Logo;
