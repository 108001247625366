import URLS from "../common/utils/apiUrls";

import ApiService from "./api.service";

const AuthService = {
    register(formData, inviteId) {
        const axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        // `${URLS.createApp}`.replace(":orgId", orgId)
        return ApiService.post(
            `${URLS.registerUser}`.replace(":inviteId", inviteId || ""),
            axiosOptions,
        );
    },

    login(formData) {
        const axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.loginUser, axiosOptions);
    },

    forgotPassword(formData) {
        const axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.forgotPassword, axiosOptions);
    },

    resetPassword(formData) {
        const axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.resetPassword, axiosOptions);
    },

    verifyEmail(code) {
        const axiosOptions = Object.assign(
            {},
            { data: code },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.verifyEmail, axiosOptions);
    },

    // loginViaGoogle(data) {
    //     let axiosOptions = Object.assign(
    //         {},
    //         { data },
    //         // { params: params },
    //         // this.getCommonHeaderOptions()
    //     );
    //     return ApiService.post(URLS.googleLogin, axiosOptions);
    // },

    logout() {
        const axiosOptions = Object.assign(
            {},
            {},
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.logoutUser, axiosOptions);
    },

    updateProfile(updateOpts) {
        const axiosOptions = {
            data: updateOpts,
        };
        return ApiService.patch(URLS.profileDetails, axiosOptions);
    },

    // updateEmail(updateOpts) {
    //     const axiosOptions = {
    //         data: updateOpts,
    //     };
    //     return ApiService.post(URLS.updateEmailInit, axiosOptions);
    // },

    // verifyEmailUpdate(code) {
    //     return ApiService.post(URLS.updateEmailComplete, {
    //         data: { code },
    //     });
    // },

    updatePassword(newPassword) {
        return ApiService.post(URLS.updatePassword, {
            data: { password: newPassword },
        });
    },

    // updateMeta(updateOpts) {
    //     return ApiService.patch(URLS.updateMeta, { data: updateOpts });
    // },
};

export default AuthService;
