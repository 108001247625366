import UserService from "services/user.service";

import store from "../common/redux/store";
import { AuthAction } from "../common/redux/actions/authActions";

const AuthGuard = async () => {
    const storeState = store.getState();
    const currPath = window?.location?.pathname;
    const isAuthPath = currPath?.includes("auth");
    const isBasePath = currPath?.length === 1;

    const searchParams = new URLSearchParams(window.location.search);

    const inviteId = searchParams.get("inviteId");
    // if we have user data in redux store then do not make the API call
    if (storeState["authenticationDetails"]["user"]) {
        // redirect any routes to /auth/* to home if the user is logged in (client-side routing)
        if (isAuthPath) {
            return (
                "/org/choose-org" + (inviteId ? `?inviteId=${inviteId}` : "")
            );
        } else {
            return "";
        }
    } else {
        try {
            const res = await UserService.getCurrentUserSession();
            store.dispatch({
                type: AuthAction.CheckUserSession,
                payload: {
                    isUserLoggedIn: true,
                    user: res.data.session.passport.user,
                },
            });
            // if user is logged in then redirect from /auth/* pages to home OR
            if (isAuthPath) {
                return (
                    "/org/choose-org" +
                    (inviteId ? `?inviteId=${inviteId}` : "")
                );
            }
            // if user is logged in then allow the requested path
            return "";
        } catch (err) {
            console.error(err);
            if (isAuthPath || isBasePath) {
                return "";
            }
            return "/auth/login";
        }
    }
};

export default AuthGuard;
