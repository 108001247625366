const executor = async guards => {
    let redirectTo = "";
    for (const guard of guards) {
        const value = await guard();
        redirectTo = value;
        if (redirectTo.length > 0) {
            break;
        }
    }
    return redirectTo;
};

export default executor;
