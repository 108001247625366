import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";

const store = configureStore({
    reducer: rootReducer,
    preloadedState: loadFromLocalStorage(),
});

function saveToLocalStorage(state) {
    try {
        const serialState = JSON.stringify(state);
        window.localStorage.setItem("reduxStore", serialState);
    } catch (e) {
        console.warn(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serialisedState = window.localStorage.getItem("reduxStore");
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
